<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '7ba07ec8-f1af-423a-a032-c1fea3f0fac8'
    }
  }
}
</script>
